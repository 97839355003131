.navbar-toggler-icon {
    background-color: rgba(128, 0, 128,0.6);
    border-radius: 20%;
  border-style: solid; /* add border */
  border-color: whitesmoke;
  border-width:1px; /* set border width */
  border-radius: 2px; /* add border radius */

}

.hamburger {
    background-color: yellow;

}



.navBarLinksStyle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 1em;
    display: flex;
    gap: 1em;
  }

 

@media (max-width: 767px) {
    .logo {
        padding-top: 2.5vh;
    }

    .navBarLinksStyle {
        width: 7.5em;
        padding:1em;
        background-color: rgba(128, 0, 128, 0.7);
        align-items: end;
        border-radius: 61% 39% 25% 75% / 41% 30% 70% 59% 
    }


}