.stackContainer {
    
    background-color: rgb(77, 2, 77, 0.8);
    padding: 2em;
    height: 100vh;
    padding-top: 12vh;

}

.stackGrid > img {
    height: 6em;
    width: 6em;
}

.stackGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding-bottom: 2vh;
    

}



.spinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
}




.loader {
  color: #4a4a4a;
  font-family: "Poppins",sans-serif;
  font-weight: 500;
  font-size: 3.1rem;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 60px;

  padding: 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
  
}

.words {
  overflow: hidden;
  padding-top: 2e;
}

.word {
  display: block;
  height: 100%;
  padding-left: 6px;
  color: #299fff;
  animation: cycle-words 5s infinite;
 
}

@keyframes cycle-words {
  10% {
    -webkit-transform: translateY(-105%);
    transform: translateY(-105%);
  }

  25% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  35% {
    -webkit-transform: translateY(-205%);
    transform: translateY(-205%);
  }

  50% {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }

  60% {
    -webkit-transform: translateY(-305%);
    transform: translateY(-305%);
  }

  75% {
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  85% {
    -webkit-transform: translateY(-405%);
    transform: translateY(-405%);
  }

  100% {
    -webkit-transform: translateY(-400%);
    transform: translateY(-400%);
  }
}



@media (max-width: 767px) {
  .stackContainer > span > img {
    height: 3.5em;
    width: 3.5em;
}

.stackContainer {
  height: auto;
  padding-top: 8vh;
  padding-bottom: 0;
}
 
}