.aboutContainer {
    background-color: rgb(77, 2, 77, 0.8);
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 14vh;
    flex-wrap: wrap;
    font-size: 1.1rem;
}


.bioCard {
    display: flex;
    flex-direction: column;
    height: 30vh;
    width: 50em;
    padding-top:1em

}

.pictureContainer {
    display: flex;
    flex-direction: column;
    gap:10vh;
    margin-top: 8vh;
    margin-right: 2em;
    
}

@keyframes vibrate {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-2px, -2px);
  }
  40% {
    transform: translate(2px, 2px);
  }
  60% {
    transform: translate(-2px, -2px);
  }
  80% {
    transform: translate(2px, 2px);
  }
  100% {
    transform: translate(0, 0);
  }
}



@media (max-width: 767px) {
    .aboutContainer {
      height: auto;
      width: auto;
      padding: 1em;
  
    }
    
    .bioCard {
      width: auto;
      height: auto;
      padding-top: 1em;
      display: flex;

    }
    
    .pictureContainer {
      margin: 0;
      gap: 2vh;
    
      
    }
  }




  /* @media () {
    .aboutContainer {
      height: auto;
      width: auto;
      padding: 1em;
  
    }
    
    .bioCard {
      width: auto;
      height: auto;
      padding-top: 1em;
      display: flex;

    }
    
    .pictureContainer {
      margin: 0;
      gap: 2vh;
    
      
    }
  } */

