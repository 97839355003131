.main {
  background-image: url("../assets/bg1.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  height: 100%;
  font-family: "Poppins",sans-serif;
  
}


.hello {
  background-image: url("../assets/bg1.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-family: "Poppins",sans-serif;
}


 @media (max-width: 767px) {


  .main {
   background-attachment: initial;  
   background-image: url("../assets/bg1 copy.png") center top no-repeat scroll;
   background-size: 100vh;
    }

  
}  


