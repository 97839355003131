.projectContainer {
  height: 100vh;
  overflow: auto;
  padding-top: 12vh;

}

.projectsTitle {
  font-weight: bolder;
  font-size: 45px;
  text-align: center;
  text-decoration: underline;
  
}

.card {
  width: 22rem;
  background-color: #212529;
  color: white;
  border-radius: 5%;
  border-color: purple;
  box-shadow: 0 0 8px 3px rgba(186, 85, 211, 0.5);
  
            
}


.projectsTitle {
  font-weight: bolder;
  font-size: 45px;
  text-align: center;
  text-decoration: underline;
  padding-bottom: 3vh;
}


.projectsImg {
  height: 2.15em;
}

.card:hover {
  box-shadow: 0 0 10px 5px rgba(186, 85, 211, 1);
}


.projectImage {
  height: 30vh;
  border-top-left-radius: 8%;
  border-top-right-radius: 8%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.buttonCode {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  font-size: 1.2rem;
  transform: translateY(0);
  transition: transform 0.2s ease-out;
  position: sticky;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  font-size: 1.2rem;
  transform: translateY(0);
  transition: transform 0.2s ease-out;
}

.button:hover, .buttonCode:hover {
  transform: translateY(-3px);
}

.button:active, .buttonCode.active {
  transform: translateY(-6px);
  transition: transform 0.2s ease-in;
}




@media (max-width: 767px) {
  .projectContainer {
    height: auto;
    width: auto;
    padding-top: 3vh;
    
  }
  .card {
    height: 100%;
    margin-bottom: 2rem;
  }

  .projectsTitle {
    padding-top: 4vh;
  }
}
