.hello {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.typed {
  margin-top: 6vh;
  margin-bottom: 10vh;
  font-size: 45px;
  text-align: center;
}

.helloImg {
  width: 15rem;
  height:15rem;
}


.btnHello {
  --glow-color: rgb(217, 176, 255);
  --glow-spread-color: rgba(191, 123, 255, 0.781);
  --enhanced-glow-color: rgb(231, 206, 255);
  --btn-color: rgb(100, 61, 136);
  border: 0.25em solid var(--glow-color);
  padding: 1em 3em;
  margin-bottom:2em;

  color: var(--glow-color);
  font-size: 15px;
  font-weight: bold;
  background-color: var(--btn-color);
  border-radius: 1em;
  outline: none;
  box-shadow: 0 0 1em 0.25em var(--glow-color),
    0 0 4em 1em var(--glow-spread-color),
    inset 0 0 0.75em 0.25em var(--glow-color);
  text-shadow: 0 0 0.5em var(--glow-color);
  position: fixed;
  text-align: center;
  top: 70vh;
  transition: all 0.3s;
  animation: glow 1s ease-out;
}


@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 rgba(113, 66, 155, 0.4);
  }
  50% {
    box-shadow: 0 0 0 15px rgba(113, 66, 155, 0.4);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(113, 66, 155, 0.4);
  }
}

.btnHello::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 120%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: 0.7;
  transform: perspective(1.5em) rotateX(35deg) scale(1, 0.6);
}

.btnHello:hover {
  color: var(--btn-color);
  background-color: var(--glow-color);
  box-shadow: 0 0 1em 0.25em var(--glow-color),
    0 0 4em 2em var(--glow-spread-color),
    inset 0 0 0.75em 0.25em var(--glow-color);
}

.btnHello:active {
  box-shadow: 0 0 0.6em 0.25em var(--glow-color),
    0 0 2.5em 2em var(--glow-spread-color),
    inset 0 0 0.5em 0.25em var(--glow-color);
}



@media (max-width: 767px) {

  .hello {
    overflow: hidden;
  }

  .helloImg {
    width: 10rem;
    height: 10rem;
  }

  .typed {
    margin-top: 0;
    
  }


  .btnHello {
    /* oryginalnie jest 3em */
    margin-top: 4em;
    position: absolute;
  }
}



